import { CognitoUserPool } from "amazon-cognito-identity-js";

// logic for aws cognito registration
const pooldata = {
  // UserPoolId: 'us-west-2_v9pnQ39Kj',
  // ClientId: '1qb5l6kr0r7haej3nfngddbip',
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_AMAZON_CLIENT_ID,
};
export default new CognitoUserPool(pooldata);

//UserPoolId: 'us-east-1_ETQdANc8H'
//ClientId: '49o6aivn936bjf9bvi20nu1g79'

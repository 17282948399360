import { surveysAnswers } from "../actions";

const initialState = {
  user_id: "",
  allSurveys: [],
  totalSurveys: -1,
  userInfo: {},
  contacts: [],
  surveyRecentlyChanged: false,
  userRecentChanges: false,
  surveysAnswers: [],
  questionsList: [],
};

const todos = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_USER":
      return {
        ...state,
        user_id: action.user_id,
      };
    case "LOGOUT_USER":
      return {
        ...state,
        user_id: "",
        allSurveys: [],
        totalSurveys: -1,
        userInfo: {},
        contacts: [],
      };
    case "ALL_SURVEYS_LIST":
      return {
        ...state,
        allSurveys: action.allSurveys,
        totalSurveys: action.allSurveys.length,
      };
    case "ALL_USER_INFO":
      return {
        ...state,
        userInfo: action.userInfo,
      };
    case "ALL_CONTACTS":
      return {
        ...state,
        contacts: action.contacts,
      };
    case "DELETE_SURVEY":
      return {
        ...state,
        allSurveys: state.allSurveys.filter(
          (item) =>
            item.database_unique_survey_id !== action.database_unique_survey_id
        ),
      };
    case "SURVEY_RECENT_CHANGES":
      return {
        ...state,
        surveyRecentlyChanged: action.value,
      };
    case "CLOSE_SURVEY":
      const index = state.allSurveys.findIndex(
        (todo) =>
          todo.database_unique_survey_id !== action.database_unique_survey_id
      ); //finding index of the item
      const newArray = [...state.allSurveys]; //making a new array
      newArray[index].status = "Closed"; //changing value in the new array
      return {
        ...state, //copying the orignal state
        allSurveys: newArray, //reassingning todos to new array
      };
    case "USER_RECENT_CHANGES":
      return {
        ...state,
        userRecentChanges: action.value,
      };
    case "SURVEYS_ANSWERS":
      return {
        ...state,
        surveysAnswers: [...surveysAnswers, action.surveyAnswerList],
      };
    case "SURVEY_QUESTIONS_CREATION":
      return {
        ...state,
        questionsList: action.questionsList,
      };
    default:
      return state;
  }
};

export default todos;

export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const ALL_SURVEYS_LIST = "ALL_SURVEYS_LIST";
export const ALL_USER_INFO = "ALL_USER_INFO";
export const ALL_CONTACTS = "ALL_CONTACTS";
export const DELETE_SURVEY = "DELETE_SURVEY";
export const SURVEY_RECENT_CHANGES = "SURVEY_RECENT_CHANGES";
export const CLOSE_SURVEY = "CLOSE_SURVEY";
export const USER_RECENT_CHANGES = "USER_RECENT_CHANGES";
export const SURVEYS_ANSWERS = "SURVEYS_ANSWERS";
export const SURVEY_QUESTIONS_CREATION = "SURVEY_QUESTIONS_CREATION";

import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React from "react";
const Login = React.lazy(() => import("./LoginNew"));
const DragDrop = React.lazy(() => import("./DragDrop"));
const Sign = React.lazy(() => import("./SignNew"));
const CreateSurvey = React.lazy(() => import("./CreateSurvey"));
const AllSurveys = React.lazy(() =>
  import("./AllSurveys/components/AllSurveys")
);
const Contacts = React.lazy(() => import("./Contacts/components/Contacts"));
const Settings = React.lazy(() => import("./SettingsGeneral/components/Main"));
const SurveyStart = React.lazy(() => import("./SurveyStart"));
const ForgotPassword = React.lazy(() => import("./ForgotPassword"));
const SendEmail = React.lazy(() => import("./SendEmail"));
const Upgrade = React.lazy(() => import("./Upgrade"));
const Landing = React.lazy(() => import("./Landing"));
const Cluster_Dash = React.lazy(() => import("./Cluster_Dash"));
const Trello = React.lazy(() => import("./Trello"));
const VerifyRating = React.lazy(() => import("./VerifyRating"));
const Scanner = React.lazy(() => import("./Scanner"));
const NewLayout = React.lazy(() => import("./NewLayout"));
const AnalyticsDashboard = React.lazy(() => import("./AnalyticsDashboard"));
const InstaAnalytics = React.lazy(() =>
  import("./Analytics/Instagram/components/InstaAnalytics")
);
const SurveyMain = React.lazy(() => import("./SurveyPage/components/Main"));
const ConsolidatedSurvey = React.lazy(() =>
  import("./ConsolidatedSurvey/components/BasicDashboard")
);
const Testing = React.lazy(() => import("./Testing"));
function App() {
  return (
    <React.Suspense
      fallback={
        <div
          style={{
            height: "100vh",
            width: "100vw",
            backgroundColor: "whitesmoke",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span>Loading...</span>
        </div>
      }
    >
      <Router>
        <div className="app">
          <Switch>
            <Route path="/cluster_dash" render={() => <Cluster_Dash />} />
            <Route path="/dragdrop" render={() => <DragDrop />} />

            <Route path="/send_email" render={() => <SendEmail />} />
            <Route path="/scanner" render={() => <Scanner />} />
            <Route path="/landing" render={() => <Landing />} />
            <Route path="/testing" render={() => <Testing />} />
            <Route
              path="/consolidate_survey/:survey_ids/:survey_type_name"
              render={() => <ConsolidatedSurvey />}
            />
            <Route
              path="/survey/:source/:database_unique_survey_id/:billing_id"
              render={() => <SurveyMain />}
              exact={true}
            />
            <Route
              path="/vsurvey/:source/:database_unique_survey_id/:send_id"
              render={() => <SurveyMain />}
              exact={true}
            />
            <Route
              path="/survey/:source/:database_unique_survey_id"
              render={() => <SurveyMain />}
              exact={true}
            />
            <Route
              path="/see_survey/:database_unique_survey_id"
              render={() => <SurveyMain />}
              exact={true}
            />
            <Route
              path="/see_survey/:database_unique_survey_id/:billing_id"
              render={() => <SurveyMain />}
              exact={true}
            />
            <Route path="/forgot_password" render={() => <ForgotPassword />} />
            <Route
              exact
              path="/main_survey/:database_unique_survey_id/:surveyName"
              render={() => <NewLayout />}
            />
            <Route
              path="/survey_start/:total_survey/:survey_type_name"
              render={() => <SurveyStart />}
            />
            <Route
              path="/exam_paper/:total_survey/:survey_type_name"
              render={() => <SurveyStart />}
            />
            <Route path="/upgrade" render={() => <Upgrade />} />
            <Route
              path="/settings"
              render={() => (
                <div>
                  <Settings />
                </div>
              )}
            />
            <Route
              path="/action"
              render={() => (
                <div>
                  <Trello />
                </div>
              )}
            />
            <Route
              path="/contacts"
              render={() => (
                <div>
                  <Contacts />
                </div>
              )}
            />
            <Route
              path="/create_survey/:total_survey"
              render={() => <CreateSurvey />}
            />
            <Route
              path="/verify_rating/:survey_type_name/:survey_id/:pdf_time"
              render={() => <VerifyRating />}
            />
            <Route path="/workspace" render={() => <AllSurveys />} />
            <Route path="/login" render={() => <Login />} />
            <Route path="/signup" render={() => <Sign />} />
            <Route path="/analytics" render={() => <AnalyticsDashboard />} />
            <Route path="/insta_analytics" render={() => <InstaAnalytics />} />
            <Route path="/" render={() => <Login />} />
          </Switch>
        </div>
      </Router>
    </React.Suspense>
  );
}

export default App;

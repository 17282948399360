import {
  LOGIN_USER,
  LOGOUT_USER,
  ALL_SURVEYS_LIST,
  ALL_USER_INFO,
  ALL_CONTACTS,
  DELETE_SURVEY,
  SURVEY_RECENT_CHANGES,
  CLOSE_SURVEY,
  USER_RECENT_CHANGES,
  SURVEYS_ANSWERS,
  SURVEY_QUESTIONS_CREATION,
} from "./types";

export const loginUser = (user_id) => ({
  type: LOGIN_USER,
  user_id: user_id,
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const allSurveysList = (allSurveys) => ({
  type: ALL_SURVEYS_LIST,
  allSurveys: allSurveys,
});

export const allUserInfo = (userInfo) => ({
  type: ALL_USER_INFO,
  userInfo: userInfo,
});

export const allContacts = (contacts) => ({
  type: ALL_CONTACTS,
  contacts: contacts,
});

export const deleteSurveyRedux = (database_unique_survey_id) => ({
  type: DELETE_SURVEY,
  database_unique_survey_id: database_unique_survey_id,
});

export const surveyRecentlyChanged = (value) => ({
  type: SURVEY_RECENT_CHANGES,
  value: value,
});

export const closeSurveyRedux = (database_unique_survey_id) => ({
  type: CLOSE_SURVEY,
  database_unique_survey_id: database_unique_survey_id,
});

export const userRecentChanges = (value) => ({
  type: USER_RECENT_CHANGES,
  value: value,
});

export const surveysAnswers = (surveyAnswerList) => ({
  type: SURVEYS_ANSWERS,
  surveyAnswerList: surveyAnswerList,
});

export const surveyQuestionsCreation = (questionsList) => ({
  type: SURVEY_QUESTIONS_CREATION,
  questionsList: questionsList,
});
